<template>
  <div class="text-center py-5" style="font-size:24px">Đang chuyển trang ...</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  layout: 'mlearn',
  computed: {
    ...mapState([
      'isMobile'
    ])
  },
  mounted () {
    if (this.isMobile) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      let link = 'https://colearn.page.link/app'
      // let link = "https://play.google.com/store/apps/details?id=vn.com.elcom.colearn"
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // link = "https://apps.apple.com/vn/app/colearn/id1548249734"
        if (this.$helper.detectUserAgent() === 'Apple Safari') {
          link = 'Colearn://'
          location.href = link
          return
        } else {
          link = 'https://apps.apple.com/vn/app/colearn/id1548249734'
        }
      }
      // var wnd = window.open(link);
      const a = document.createElement('a')
      a.setAttribute('href', link)
      a.setAttribute('target', '_blank')
      a.click()
      // setTimeout(function() {
      //   wnd.close();
      // }, 5000);
      // location.href=link
      // window.open(link,'_blank')
    }
    this.$router.push('/')
  }
}
</script>

<style lang="scss" scoped>

</style>
