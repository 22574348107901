<template>
  <div class="container ml-user-info mb-5">
    <div class="breadcrumd-custom d-none">
      <nuxt-link to="/">Tài liệu</nuxt-link>
      <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon"/> <span class="active">Trang cá nhân</span></div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="block-data">
          <div class="form-data user-info">
            <div class="user-avatar mr-3">
              <img :src="user.avatar || NoAvatar" alt="avatar" class="avatar rounded-circle"/>
              <div class="change-avatar">
                <span class="pointer text-avt" @click="$refs.fileAvatar.click()">Đổi avatar</span>
              </div>
            </div>
            <div class="content">
              <div class="user-name">{{ $store.state.user.fullName }}</div>
              <div class="user-detail" v-if="typeOfLevel === 'level' || typeOfLevel === 'hạng'">
                {{ typeOfLevel === 'level' ? 'Level: ' : 'Hạng: ' }} <span>{{ user.level }}</span>
              </div>
              <div class="user-detail" v-else>
                <span>Hạng: 0</span>
              </div>
              <div class="user-detail" v-if="user.score">
                Điểm: <span>{{ user.score }}</span>
              </div>
              <div class="user-detail" v-else>Điểm: 0</div>
              <div class="change-avatar">
                <input type="file" ref="fileAvatar" hidden @change="onFileChangeAvatar" accept="image/*"/>
                <!--                <span class="pointer d-none d-lg-block" @click="$refs.fileAvatar.click()">Đổi avatar</span>-->
              </div>
            </div>
          </div>
          <div class="generate-info">
            <div class="title">Thông tin chung <span v-if="!editGeneralInfor" class="float-right pointer text-primary" @click="openInputForm('general-infor')">Chỉnh sửa</span></div>
            <div class="row row-item">
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Họ tên: <span class="text-danger">*</span></label>
                  <input :disabled="!editGeneralInfor" v-model="user.fullName" placeholder="Nhập họ tên" type="text" class="user-select-none"/>
                </div>
              </b-col>
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Lớp: <span class="text-danger">*</span></label>
                  <input v-if="!editGeneralInfor" disabled :value="user.classLevel" placeholder="Chọn lớp" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.classLevel" placeholder="Chọn lớp" :items="optionsClass" @select="setClassLevel"/>
                </div>
              </b-col>
            </div>
            <div class="row row-item">
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Giới tính:</label>
                  <input v-if="!editGeneralInfor" disabled :value="user.gender | convertGender" placeholder="Chọn giới tính" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.gender" placeholder="Giới tính" :items="optionsGender" :require="true" @select="setGender"/>
                </div>
              </b-col>
<!--              <b-col md="6" sm="12" cols="12">-->
<!--                <div class="group-item">-->
<!--                  <label>Trường: <span class="text-danger">*</span></label>-->
<!--                  <input v-if="!editGeneralInfor" disabled :value="user.gender | convertGender" placeholder="Chọn giới tính" type="text" class="user-select-none"/>-->
<!--                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.gender" placeholder="Giới tính" :items="optionsGender" :require="true" @select="setGender"/>-->
<!--                </div>-->
<!--              </b-col>-->
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Tỉnh/Thành phố: <span class="text-danger">*</span></label>
                  <input v-if="!editGeneralInfor" disabled :value="user.address" placeholder="Chọn tỉnh/thành phố" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.address" placeholder="Chọn tỉnh/thành phố" :items="optionsCity" @select="setAddress"/>
                </div>
              </b-col>
            </div>
            <div class="row row-item">
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Ngày sinh: <span class="text-danger">*</span></label>
                  <div class="group-time position-relative">
                    <input :disabled="!editGeneralInfor" :value="formatDate(user.birthDay)" placeholder="Chọn ngày sinh" type="text" class="user-select-none"/>
                    <b-form-datepicker v-if="editGeneralInfor" hide-header class="position-center index-up" button-only locale="vi" button-variant="tranparent p-0" v-model="user.birthDay" value-type="date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :max="maxDate">
                      <template #button-content>
                        <img src="~/mlearn/icons/calander.svg" class="" alt=""/>
                      </template>
                    </b-form-datepicker>
                  </div>
                </div>
              </b-col>
            </div>
            <div class="row row-item">
              <b-col md="6" sm="12" cols="12">
              </b-col>
<!--              <b-col md="6" sm="12" cols="12">-->
<!--                <div class="group-item">-->
<!--                  <label>Quận/Huyện: <span class="text-danger">*</span></label>-->
<!--                  <input v-if="!editGeneralInfor" disabled :value="user.address" placeholder="Chọn quận/huyện" type="text" class="user-select-none"/>-->
<!--                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.address" placeholder="Chọn tỉnh/thành phố" :items="optionsCity" @select="setAddress"/>-->
<!--                </div>-->
<!--              </b-col>-->
            </div>
            <div v-if="editGeneralInfor" class="text-right col-xl-12 mt-4">
              <button class="btn-cancel mr-3" @click="closeInputForm('general-infor')">Hủy</button>
              <button class="btn-save" @click="saveUserInfor('general-infor')">Lưu chỉnh sửa</button>
            </div>
          </div>
          <div class="generate-info d-none">
            <div class="title">Thông tin chung <span v-if="!editGeneralInfor" class="float-right pointer text-primary" @click="openInputForm('general-infor')">Chỉnh sửa</span></div>
            <div class="row row-item">
              <div class="col-lg-6 col-xl-4">
                <div class="group-item">
                  <label>Họ tên: <span class="text-danger">*</span></label>
                  <input :disabled="!editGeneralInfor" v-model="user.fullName" placeholder="Nhập họ tên" type="text" class="user-select-none"/>
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="group-item">
                  <label>Ngày sinh: <span class="text-danger">*</span></label>
                  <div class="group-time position-relative">
                    <input :disabled="!editGeneralInfor" :value="formatDate(user.birthDay)" placeholder="Chọn ngày sinh" type="text" class="user-select-none"/>
                    <b-form-datepicker v-if="editGeneralInfor" hide-header class="position-center index-up" button-only locale="vi" button-variant="tranparent p-0" v-model="user.birthDay" value-type="date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :max="maxDate">
                      <template #button-content>
                        <img src="~/mlearn/icons/calander.svg" class="" alt=""/>
                      </template>
                    </b-form-datepicker>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-2">
                <div class="group-item">
                  <label>Giới tính: <span class="text-danger">*</span></label>
                  <input v-if="!editGeneralInfor" disabled :value="user.gender | convertGender" placeholder="Chọn giới tính" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.gender" placeholder="Giới tính" :items="optionsGender" :require="true" @select="setGender"/>
                </div>
              </div>
              <div class="d-none d-xl-block w-100"></div>
              <div class="col-lg-6 col-xl-4">
                <div class="group-item">
                  <label>Điện thoại:</label>
                  <input disabled :value="user.mobile" type="text" class="user-select-none"/>
                </div>
              </div>
              <div class="col-lg-6 col-xl-4">
                <div class="group-item">
                  <label>Email:</label>
                  <input disabled :value="user.email" type="text" class="user-select-none"/>
                </div>
              </div>
              <div class="d-none d-xl-block w-100"></div>
              <div class="col-lg-6 col-xl-4">
                <div class="group-item">
                  <label>Tỉnh/Thành phố: <span class="text-danger">*</span></label>
                  <input v-if="!editGeneralInfor" disabled :value="user.address" placeholder="Chọn tỉnh/thành phố" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.address" placeholder="Chọn tỉnh/thành phố" :items="optionsCity" @select="setAddress"/>
                </div>
              </div>
              <div class="col-lg-6 col-xl-2">
                <div class="group-item">
                  <label>Lớp: <span class="text-danger">*</span></label>
                  <input v-if="!editGeneralInfor" disabled :value="user.classLevel" placeholder="Chọn lớp" type="text" class="user-select-none"/>
                  <SelectGenerate class="group-select w-100" v-if="editGeneralInfor" :selected-value="user.classLevel" placeholder="Chọn lớp" :items="optionsClass" @select="setClassLevel"/>
                </div>
              </div>
              <div v-if="editGeneralInfor" class="text-right col-xl-12">
                <button class="btn-cancel mr-3" @click="closeInputForm('general-infor')">Hủy</button>
                <button class="btn-save" @click="saveUserInfor('general-infor')">Lưu chỉnh sửa</button>
              </div>
            </div>
          </div>
        </div>
        <div class="block-data mt-3">
          <div class="generate-info">
            <div class="title">Thông tin tài khoản <span v-if="!editUserInfo && (!user.mobile || !user.email)" class="pointer text-primary float-right" @click="openInputForm('user-info')">Chỉnh sửa</span></div>
            <div class="row row-item">
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Số điện thoại:</label>
                  <input :disabled="!editUserInfo || user.mobile" v-model="userMobile" type="text" class="user-select-none"/>
                </div>
              </b-col>
              <b-col md="6" sm="12" cols="12">
                <div class="group-item">
                  <label>Email:</label>
                  <input :disabled="!editUserInfo || user.email" v-model="userEmail" type="text" class="user-select-none"/>
                </div>
              </b-col>
            </div>
            <div v-if="editUserInfo" class="text-right col-xl-12 mt-4">
              <button class="btn-cancel mr-3" @click="closeInputForm('user-info')">Hủy</button>
              <button class="btn-save" @click="saveUserInfor('user-info')">Lưu chỉnh sửa</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <ImageCropper v-show="imagecropperShow" title="Cập nhật ảnh đại diện" :file="filePreview" :rotation="rotation" :key="0" :width="avatarWith" :height="avatarHeight" field="file" url="/upload/user/avatar" lang-type="vi" @close="cropCancel" @crop-upload-success="cropSuccess"/>
    </client-only>
    <VerifyOtpModal @verify-opt-success="verifyOptSuccess"/>
  </div>
</template>

<script>
import moment from 'moment'
import Resource from '~/common/api/resource'
import ImageCropper from '~/components/mlearn/extra/ImageCropper'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import SelectGenerate from '~/components/mlearn/select/generate.vue'
import VerifyOtpModal from '~/components/mlearn/verify/otp.vue'

const userResource = new Resource('profile/user')
const updateAvatarResource = new Resource('user')
const getUserScoreResource = new Resource('score-management/score-info')

export default {
  components: {
    ImageCropper,
    VerifyOtpModal,
    SelectGenerate
  },
  filters: {
    convertGender (gender) {
      if (gender === 1) {
        return 'Nam'
      }
      if (gender === 0) {
        return 'Nữ'
      }
      return 'Khác'
    }
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  data () {
    return {
      NoAvatar,
      processing: false,
      imagecropperShow: false,
      rotation: 0,
      avatarHeight: 200,
      avatarWith: 200,
      typeOfLevel: '',
      filePreview: {
        data: null
      },
      userMobile: '',
      userEmail: '',
      user: {},
      oldDataUser: {},
      editGeneralInfor: false,
      editUserInfo: false,
      optionsGender: [
        {
          value: null,
          label: 'Chọn giới tính'
        },
        {
          value: 1,
          label: 'Nam'
        },
        {
          value: 0,
          label: 'Nữ'
        }
      ],
      optionsClass: [
        {
          value: null,
          label: 'Chọn lớp'
        },
        'Lớp 6',
        'Lớp 7',
        'Lớp 8',
        'Lớp 9',
        'Lớp 10',
        'Lớp 11',
        'Lớp 12'
      ],
      optionsCity: [
        {
          value: null,
          label: 'Chọn tỉnh thành phố'
        },
        'An Giang',
        'Bà Rịa - Vũng Tàu',
        'Bạc Liêu',
        'Bắc Kạn',
        'Bắc Giang',
        'Bắc Ninh',
        'Bến Tre',
        'Bình Dương',
        'Bình Định',
        'Bình Thuận',
        'Bình Phước',
        'Cà Mau',
        'Cao Bằng',
        'Cần Thơ',
        'Đà Nẵng',
        'Đắk Lắk',
        'Đắk Nông',
        'Điện Biên',
        'Đồng Nai',
        'Đồng Tháp',
        'Gia Lai',
        'Hà Giang',
        'Hà Nam',
        'Hà Nội',
        'Hà Tây',
        'Hà Tĩnh',
        'Hải Dương',
        'Hải Phòng',
        'Hòa Bình',
        'Hậu Giang',
        'Hưng Yên',
        'Khánh Hòa',
        'Kiên Giang',
        'Kon Tum',
        'Lai Châu',
        'Lào Cai',
        'Lạng Sơn',
        'Lâm Đồng',
        'Long An',
        'Nam Định',
        'Nghệ An',
        'Ninh Bình',
        'Ninh Thuận',
        'Phú Thọ',
        'Phú Yên',
        'Quảng Bình',
        'Quảng Nam',
        'Quảng Ngãi',
        'Quảng Ninh',
        'Quảng Trị',
        'Sóc Trăng',
        'Sơn La',
        'Tây Ninh',
        'Thái Bình',
        'Thái Nguyên',
        'Thanh Hóa',
        'Thành phố Hồ Chí Minh',
        'Thừa Thiên Huế',
        'Tiền Giang',
        'Trà Vinh',
        'Tuyên Quang',
        'Vĩnh Long',
        'Vĩnh Phúc',
        'Yên Bái'
      ],
      mobile: ''
    }
  },
  computed: {
    maxDate () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      const day = new Date().getDate()
      return year + '-' + month + '-' + day
    }
  },
  created () {
    this.getUserInfor()
  },
  methods: {
    async getUserInfor () {
      const {data} = await userResource.list()
      this.user = data
      this.userMobile = this.user.mobile
      this.userEmail = this.user.email
      this.user.birthDay = this.user.birthDay ? moment(this.user.birthDay, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
      this.getUserScore()
    },
    async getUserScore () {
      const query = {
        userId: this.user.uuid,
        pointTypeCode: 'DEFAULT'
      }
      const {data} = await getUserScoreResource.list(query)
      if (data) {
        if (data.levelName) {
          if (data.levelName.slice(0, 5).toLowerCase().includes('level')) {
            this.typeOfLevel = 'level'
            this.user.level = data.levelName.slice(6)
          } else if (data.levelName.slice(0, 4).toLowerCase().includes('hạng')) {
            this.typeOfLevel = 'hạng'
            this.user.level = data.levelName.slice(5)
          } else {
            this.user.level = data.levelName
          }
          this.user.score = data.currentPoint
        }
      }
    },
    onFileChangeAvatar (e) {
      const files = e.target.files
      if (!files.length) {
        return
      }
      const file = files[0]
      this.$helper.getOrientation(file, (rotation) => {
        this.rotation = rotation
      })
      this.checkFile(file)
        .then(() => {
          this.filePreview.data = file
          this.imagecropperShow = true
        })
        .catch((err) => {
          this.$notify({
            title: 'Lỗi',
            message: err,
            type: 'error'
          })
        })
    },
    checkFile (file) {
      return new Promise((resolve, reject) => {
        const self = this
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            const height = this.height
            const width = this.width
            if (height < self.avatarHeight || width < self.avatarWith) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return reject('Kích thước hình ảnh quá nhỏ. Tối thiểu là: ' + self.avatarWith + '*' + self.avatarHeight)
            }
            return resolve()
          }
        }
      })
    },
    cropSuccess (data) {
      const linkAvatar = data.fileDownloadUri
      if (linkAvatar) {
        this.updateAvatar(linkAvatar)
        this.user.avatar = linkAvatar
        this.$store.commit('SET_USER_AVATAR', linkAvatar)
      }
    },
    cropCancel () {
      this.$refs.fileAvatar.value = ''
      this.imagecropperShow = false
    },
    updateAvatar (avatar) {
      updateAvatarResource.put({avatar})
        .then(() => {
          this.$notify({
            title: 'Thành công',
            message: 'Cập nhật avatar thành công',
            type: 'success'
          })
        })
        .catch(() => {
          this.$notify({
            title: 'Lỗi',
            message: 'Có lỗi xảy ra vui lòng thử lại sau!',
            type: 'error'
          })
        })
    },
    verifyOptSuccess () {
      this.user.mobile = this.mobile
      this.$store.dispatch('setUserMobile', this.mobile)
      this.mobile = ''
    },
    openInputForm (event) {
      if (event === 'general-infor') {
        this.editGeneralInfor = true
      }
      if (event === 'user-info') {
        this.editUserInfo = true
      }
      this.oldDataUser = Object.assign({}, this.user)
    },
    closeInputForm (event) {
      if (event === 'general-infor') {
        this.editGeneralInfor = false
        this.user = Object.assign({}, this.oldDataUser)
      }
      if (event === 'user-info') {
        this.editUserInfo = false
        this.user = Object.assign({}, this.oldDataUser)
      }
    },
    saveUserInfor (event) {
      if (event === 'general-infor') {
        this.user.fullName = this.user.fullName.trim()
        this.user.birthDay = this.user.birthDay.trim()
        this.user.classLevel = this.user.classLevel ? this.user.classLevel.trim() : ''
        this.user.address = this.user.address ? this.user.address.trim() : ''
        // this.user.mobile = this.user.mobile ? this.user.mobile.trim() : ''
        // this.user.email = this.user.email ? this.user.email.trim() : ''
        this.$notify.closeAll()
        if (this.processing) {
          return
        }
        if (!this.user.fullName) {
          this.$notify.error({
            message: 'Họ tên không được để trống'
          })
          return
        }
        if (this.user.fullName.length > 50) {
          this.$notify.error({
            message: 'Họ tên không được dài quá 50 kí tự'
          })
          return
        }

        if (!this.user.birthDay) {
          this.$notify.error({
            message: 'Ngày sinh không được để trống'
          })
          return
        }
        if (this.user.gender === null || this.user.gender === '') {
          console.log(typeof this.user.gender)
          this.$notify.error({
            message: 'Giới tính không được để trống'
          })
          return
        }
        if (!this.user.classLevel) {
          this.$notify.error({
            message: 'Lớp không được để trống'
          })
          return
        }
        if (!this.user.address) {
          this.$notify.error({
            message: 'Tỉnh thành phố không được để trống'
          })
          return
        }
        // if (!this.user.mobile) {
        //   this.$notify.error({
        //     message: 'Số điện thoại không được để trống'
        //   })
        //   return
        // }
        // if (!this.user.email) {
        //   this.$notify.error({
        //     message: 'Email không được để trống'
        //   })
        //   return
        // }
        const dataUpdate = {
          uuid: this.user.uuid,
          fullName: this.user.fullName,
          birthDay: this.formatDate(this.user.birthDay),
          gender: this.user.gender,
          address: this.user.address,
          classLevel: this.user.classLevel
          // mobile: this.user.mobile,
          // email: this.user.email
        }
        if (this.processing) {
          return
        }
        this.processing = true
        userResource
          .store(dataUpdate)
          .then((response) => {
            if (response.status === 200) {
              this.$store.dispatch('setUserFullName', response.data.fullName)
              this.$notify.success({
                message: 'Cập nhật dữ liệu thành công'
              })
              this.editGeneralInfor = false
            }
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              message: error.response.data.message
            })
          })
          .finally(() => {
            this.processing = false
          })
      } else if (event === 'user-info') {
        let checkError = false
        let message = ''
        this.userMobile = this.userMobile ? this.userMobile.trim() : ''
        this.userEmail = this.userEmail ? this.userEmail.trim() : ''
        if (!this.userMobile) {
          this.$notify.error({
            message: 'Số điện thoại không được để trống'
          })
          return
        }
        if (!this.userEmail) {
          this.$notify.error({
            message: 'Email không được để trống'
          })
          return
        }
        if (!this.$helper.validPhone(this.userMobile)) {
          message = 'Vui lòng kiểm tra lại số điện thoại'
          checkError = true
        }
        if (checkError) {
          this.$notify({
            message,
            type: 'warning'
          })
          return
        }
        if (!this.$helper.validEmail(this.userEmail)) {
          message = 'Vui lòng kiểm tra lại email'
          checkError = true
        }
        if (checkError) {
          this.$notify({
            message,
            type: 'warning'
          })
          return
        }
        const dataUpdate = {
          uuid: this.user.uuid,
          mobile: this.userMobile,
          email: this.userEmail
        }
        if (this.processing) {
          return
        }
        this.processing = true
        userResource
          .store(dataUpdate)
          .then((response) => {
            if (response.status === 200) {
              // this.$store.dispatch('setUserFullName', response.data.fullName)
              this.user.mobile = this.userMobile
              this.user.email = this.userEmail
              this.$notify.success({
                message: 'Cập nhật dữ liệu thành công'
              })
              this.editUserInfo = false
            }
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              message: error.response.data.message
            })
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    setGender (value) {
      this.setData(value, 'gender')
    },
    setAddress (value) {
      this.setData(value, 'address')
    },
    setClassLevel (value) {
      this.setData(value, 'classLevel')
    },
    setData (value, field) {
      this.user[field] = value
    },
    formatDate (date) {
      if (!date) {
        return ''
      }
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    showVerifyMobile (mobile) {
      this.mobile = mobile
      this.$bvModal.show('modal-verify-otp')
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-user-info {
  .block-data {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    transition: all .4s;
    padding: 24px;
    @media (max-width: 576px) {
      padding: 16px;
    }

    .position-center {
      left: auto;
    }

    .user-info {
      margin-bottom: 2rem;
      @media (max-width: 576px) {
        margin-bottom: 1rem;
      }

      .avatar {
        width: 94px;
        height: 94px;
        @media (max-width: 576px) {
          width: 68px;
          height: 68px;
        }
      }

      .content {
        .user-name {
          padding-bottom: 5px;
          font-weight: 700;
          font-size: 20px;
          color: #333333;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        .user-detail {
          padding-bottom: 5px;
          font-weight: 500;
          font-size: 14px;
          color: #6B6D70;

          span {
            color: initial;
            font-weight: bold;
          }
        }
      }

      .change-avatar {
        color: #004390;
        font-weight: 500;
        font-size: 14px;
        padding-top: 8px;
        padding-left: 14px;
        @media (max-width: 576px) {
          padding-left: 8px;
        }
      }
    }

    .generate-info {
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #262F3E;
        @media (max-width: 1366px) {
          font-size: 20px;
        }
        @media (max-width: 576px) {
          font-size: 16px;
        }

        span {
          font-weight: 500;
          font-size: 16px;
          @media (max-width: 576px) {
            font-size: 14px;
          }
          @media (max-width: 1366px) {
            font-size: 15px;
          }
        }
      }

      .btn-cancel {
        background: var(--white);
        border: 1px solid #004390;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #004390;
        padding: 10px 0;
        width: 120px;
        @media (max-width: 576px) {
          padding: 8px 0;
          width: 100px;
        }
        @media (max-width: 1366px) {
          padding: 8px 0;
          width: 100px;
        }
      }

      .btn-save {
        border: 1px solid #004390;
        border-radius: 5px;
        background: #004390;
        text-align: center;
        color: var(--white);
        padding: 10px 0;
        font-size: 14px;
        width: 120px;
        @media (max-width: 576px) {
          padding: 8px 0;
          width: 100px;
        }
        @media (max-width: 1366px) {
          padding: 8px 0;
        }
      }

      .row-item {
        > [class*="col-"] {
          padding-top: 20px;
          @media (max-width: 576px) {
            padding-top: 10px;
          }
          @media (max-width: 1366px) {
            padding-top: 10px;
          }

          .group-item {
            margin-right: 24px;
            @media (max-width: 576px) {
              margin-right: 16px;
            }

            &:last-child {
              margin-right: 0;
            }

            label {
              font-size: 14px;
              margin-bottom: 3px;
              color: #262F3E;
              font-weight: 500;
            }

            input {
              font-weight: 500;
              font-size: 14px;
              border: 1px solid #CED4DA !important;
              border-radius: 8px;
              background: var(--white);
              width: 100%;
              padding: 12px 16px;
              @media (max-width: 576px) {
                padding: 8px 10px;
              }
              @media (max-width: 1366px) {
                padding: 8px 10px;
              }

              &::placeholder {
                color: #A4A4A5;
              }

              &:disabled {
                background: #F7F7F7;
                color: #A4A4A5;
              }
            }

            select {
              height: auto;
              font-weight: 500;
              font-size: 14px;
              border: 1px solid #CED4DA !important;
              border-radius: 8px;
              width: 100%;
              padding: 12px 16px;
              @media (max-width: 576px) {
                padding: 8px 10px;
              }
              @media (max-width: 1366px) {
                padding: 8px 10px;
              }

              &::placeholder {
                color: #A4A4A5;
              }

              &:disabled {
                background: #F7F7F7;
                color: #A4A4A5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ml-user-info {
  .block-data {
    .generate-info {
      .row-item {
        > [class*="col-"] {
          .group-item {
            .group-time {
              .b-form-datepicker {
                right: 10px;
              }
            }

            .group-select {
              display: block;

              .custom-select {
                height: auto;
                font-weight: 500;
                font-size: 14px;
                padding: 12px 16px;
                @media (max-width: 576px) {
                  padding: 8px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
